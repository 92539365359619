/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Footer from "./footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./layout.css";
import Section from "./section";
import Boys from "./boys";
import ContactForm from "./contactForm";
import H1 from "./sectionHeading";
import { Row, Col } from "react-bootstrap";
//import DriftLoader from "./driftLoader";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js'
import cg from '../cg-config';

// See https://www.gatsbyjs.org/tutorial/ecommerce-tutorial/
// Keep loadStripe outside of render()
const stripePromise = loadStripe(cg.stripe.apiKey); 

const Layout = ({ children }) => {

  return (
    <Elements stripe={stripePromise}>
        <div style={{
              margin: 0,
              width: '100%',
              padding: 0
            }}>
          <main id='main' style={{
              margin: 0,
              padding: 0,
            }}>{children}</main>
          
              
          <ContactForm />
          
          {/*
          <hr/>
          <Section id='support' className='cg-section-shaded'>
            <Row>
              <Col>
                <H1>Pass it on</H1>
                <p>
                  When you choose Congius, you also support families with disabled children:
                </p>
              </Col>
            </Row>
            <Boys/>
          </Section>
          */}
          <Footer />
        </div>
      {/* <DriftLoader /> */}
    </Elements>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
