import React from "react";


class H1 extends React.Component {
  //constructor(props) {
  //    super(props);
  //}

  render() {
    return (
      <div>
        <div style={{margin: '2rem'}}>
          <h1 className='cg-h1'>
            {this.props.children}
          </h1>
          <hr style={{maxWidth: '40vw', margin: '0 auto'}}/>
        </div>
      </div>
    );
  }
}

export default H1;