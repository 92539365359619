module.exports = {
    // // TEST 1 (cloud9)
    // stripe:{
    //     apiKey: 'pk_test_kc8i5lFOXWsqTqk7lzh1Scsj00Gux0SGEz',
    //     baseUrl: 'https://7587adae1137495588b61e3ce9597f3e.vfs.cloud9.us-east-1.amazonaws.com',
    //     priceMaint: 'price_1HFnbDFAOp6LoATJNhoxzvhN',
    // }
    
    // // TEST 2 (production site, test Stripe data)
    // stripe:{
    //     apiKey: 'pk_test_kc8i5lFOXWsqTqk7lzh1Scsj00Gux0SGEz',
    //     baseUrl: 'https://www.congius.org',
    //     priceMaint: 'price_1HFnbDFAOp6LoATJNhoxzvhN',
    // }
    
    // PROD
    stripe:{
        apiKey: 'pk_live_bGm7dQNWm0CtDLxK1SR1CEaa002JI0vhVV',
        baseUrl: 'https://www.congius.org',
        priceMaint: 'plan_GvueyVyBVOHvvj',
    }
}