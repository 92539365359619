import React from "react";
import {Container,Row,Col} from "react-bootstrap";
import { Link } from 'gatsby';

const Footer = () => (
  <footer>
    <Container fluid  style={{textAlign:'left', margin:0 }}>
        {/*
      <Row>
        <Col sm>
          <ul style={{listStyle: 'none'}}>
            <li><a href="mailto:info@congius.org">info@congius.org</a></li>
            <li>© {new Date().getFullYear()}</li>
          </ul>
        </Col>
        <Col sm>
          <Link to='services'>Services</Link>: 
          <ul>
            <li><Link to='services#websites'>Websites</Link></li>
            <li><Link to='services#copywriting'>Copywriting</Link></li>
            <li><Link to='services#marketing'>Marketing</Link></li>
          </ul>
        </Col>
      </Row>
        */}
      <Row>
        <Col style={{ textAlign:'center', padding: '1rem'}} >
          <small>
            This site is protected by reCaptcha. Google&nbsp;<a href="https://policies.google.com/terms">Terms of Service</a> and <a href="https://policies.google.com/privacy">Privacy Policy</a> apply.
          </small>
        </Col>
      </Row>
    </Container>
  </footer>
)

export default Footer
