import React, {Component} from "react";
//import ReactDOM from 'react-dom';
import { 
  Form, 
  Button, 
  Alert,
  Container, 
  Row, 
  Col, 
} from "react-bootstrap";
import axios from "axios";
//import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import H1 from "./sectionHeading";

export default class ContactForm extends Component {
  state = {
    sendButton : {
      message: 'Send',
      disabled: false
    },
    alert:{
      success:false,
      error: false
    },
    captchaToken:undefined
  };
  
  resetForm() {
    this.setState({
      sendButton : {
        message: 'Send',
        disabled: false
      }
    });
  }
  
  // componentDidMount() {
  //   loadReCaptcha('6LfH774UAAAAAKGMRIXnvjnT2Ma9iyWHMhX65yk4');
  //   //console.log("===ContactForm.componentDidMount()===")
  // }
  
  // // When the user clicks in the email/phone field
  // handleEmailFocus() {
  //   // Call the Google reCAPTCHA script
  //   // This starts a two minute ttl for the token
  //   // Called here instead of in handleSubmit because it needs time to return before submitting the form
  //   const recaptcha = 
  //     <ReCaptcha
  //       sitekey="6LfH774UAAAAAKGMRIXnvjnT2Ma9iyWHMhX65yk4"
  //       action='contact_form_entry'
  //       verifyCallback={this.verifyCallback}
  //     />;
  //   ReactDOM.render(recaptcha,document.getElementById('contactForm'));
  // }
  
  // Save the reCAPTCHA token in the form state
  verifyCallback = (callbackToken) => {
    // Here you will get the final recaptchaToken!!!  
    //console.log(recaptchaToken, "<= your recaptcha token")
    this.setState({captchaToken:callbackToken});
  }

  handleSubmit = async(event) => {
    event.preventDefault();
    this.setState({ sendButton: { message: 'Sending...', disabled: true }});
    console.log(event);
    
    try {
      const params = {
        formCgFormId: '7ns1cg',
        formEmail: event.target.formEmail.value, 
        formPhone: event.target.formPhone.value, 
        formWho: event.target.formWho.value, 
        formWhat: event.target.formWhat.value,
        captchaToken: this.state.captchaToken
      };
      await axios.post(`https://3blwbf5x4f.execute-api.us-east-1.amazonaws.com/prod/contact`, params);
      console.log('success!');
      this.setState({ sendButton : { message: 'Sent!', disabled: true }, alert: {success: true, error:false}});
    }
    catch (err) {
      console.log('error!');
      console.log(err);
      // ToDo update message to user (use a bootstrap component) with an alternate contact method
      this.setState({ sendButton : { message: 'Oops!', disabled: true }, alert: {success:false, error: true}});
    }
    
    // The code below worked except it scrolled too far...
    //document.getElementById('sendAlert').scrollIntoView();
    
    // Reset send button
    setTimeout(function(){ this.resetForm(); }.bind(this), 3000);
  }

  render() {
    return (
      <div id="contactForm" style={{padding:'0.6rem', maxWidth:'40rem', margin:'0 auto 4rem auto'}}>
        <H1>Contact</H1>
        
        <Container fluid>
          <Row>
            <Col md>
              <Row>
                <Col>
                  <p>Send me a message with this form, or by email. I'll get back to you as soon as I can.</p>
                  <dl>
                    <dt className="contactType"><FontAwesomeIcon icon={faEnvelope} /> Email</dt>
                    <dd><a href="mailto:ben@congius.org" className='cg-linkDark'>ben@congius.org</a></dd>
                    {/*
                    <dt><FontAwesomeIcon icon={faPhoneAlt} /> Voice / Text</dt>
                    <dd><a href="tel:+13154129960" className='cg-linkDark'>(315)&nbsp;412&#8209;9960</a></dd>
                    */}
                  </dl>
                </Col>
              </Row>
            </Col>
            <Col md>
              <Form onSubmit={event => this.handleSubmit(event)}>
                
                <Form.Group controlId="formEmail">
                  <Form.Label srOnly='true'>Your best email address</Form.Label>
                  <Form.Control placeholder="Your best email address"/>
                </Form.Group>
                
                <Form.Group controlId="formPhone">
                  <Form.Label srOnly='true'>Phone</Form.Label>
                  <Form.Control placeholder="Phone number"/>
                </Form.Group>
                
                <Form.Group controlId="formWho">
                  <Form.Label srOnly='true'>Name and/or company</Form.Label>
                  <Form.Control placeholder="Name and/or company" />
                </Form.Group>
                
                <Form.Group controlId="formWhat">
                  <Form.Label srOnly='true'>Comments</Form.Label>
                  <Form.Control as="textarea" rows="3" placeholder="How can I help you?" />
                </Form.Group>
                
                <Button type="submit" className="cg-dark" disabled={this.state.sendButton.disabled}>
                  {this.state.sendButton.message}
                </Button>
              </Form>
              
              <span id='sendAlert'>
                <Alert variant='success' show={this.state.alert.success}>
                  Thanks for contacting me.  I'll get back to you soon!
                </Alert>
                <Alert variant='warning' show={this.state.alert.error}>
                  Oops! The message could not go though.  Please email info@congius.org or call (315)&nbsp;412&#8209;9960. 
                </Alert>
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
