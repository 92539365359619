import React from "react";
import H1 from './sectionHeading';
import { Container, Row, Col, Button } from "react-bootstrap";

const Section = function(props) {
  return (
    <div id={props.id}  className={props.className}>
        <div className='cg-section'>
          <Container fluid>
            {props.title &&
            <Row>
              <Col>
                <H1>{props.title}</H1>
              </Col>
            </Row>
            }
            
            {props.children}
           
            {(props.title || props.contact)&&
            <Row>
              <Col>
                <Button className="cg-dark" href="#contactForm" >Contact Us</Button>
              </Col>
            </Row>
            }
          </Container>
        </div>
        {!props.nohr && <hr />}
      </div>
      );
}

export default Section;